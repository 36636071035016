<template>
	<div class="pb-4"
		:class="{
			'-mt-4': anEvent.status === 'accepted'
		}">
		<section v-if="anEvent.status === 'accepted'">
			<div>
				<b-breadcrumb>
					<b-breadcrumb-item href="#/events/list">Events</b-breadcrumb-item>
					<i class="px-2 inactive fe fe-chevron-right"></i>
					<b-breadcrumb-item active-class="active" active
						>Event information</b-breadcrumb-item
						>
				</b-breadcrumb>
			</div>
		</section>
		<div class="d-xl-flex">
			<div class="col-xl-6">
				<div class="">
					<div class="">
						<event-information-card
							:title="anEvent.title"
							:location="anEvent.location"
							:payment="anEvent.payment"
							:returnTrip="anEvent.returnTrip"
							:tripDate="formatDate(anEvent.tripDate)"
							:returnDate="formatDate(anEvent.returnDate)"
							:attendeesEstimate="anEvent.attendeesEstimate"
							:eventLink="anEvent.eventLink"
							:type="anEvent.type"
							:loading="isLoading"
              :route-assigned="anEvent.routeAssigned"
              :event-route-link="anEvent.eventRouteLink"
							></event-information-card>
					</div>
					<div class="">
						<event-customer-information
							:firstName="customerDetails.firstName"
							:lastName="customerDetails.lastName"
							:email="customerDetails.email"
							:phone="customerDetails.phone"
							:loading="isLoading"
							></event-customer-information>
					</div>
				</div>
			</div>
			<div class="col-xl-6">
				<div class="">
					<div class="">
						<event-information-description-card
							:title="anEvent.title"
							:description="anEvent.description"
							:status="anEvent.status"
							:banner="anEvent.banner"
							:loading="isLoading"
							:isOrganizerNotificationLoading="isOrganizerNotificationLoading"
							@onNotifyOrganizer="notifyOrganizer"
							></event-information-description-card>
					</div>
					<div
						v-if="this.anEvent.status === 'accepted'"
						class=""
						>
						<assign-route :routes="eventRoutes" :loading="routesLoading" @onDelete="updateEventRoutes" @onEdited="assignRoutes"/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
// import Swal from 'sweetalert2'
// import routeResource from '@/api/route'
import AssignRoute from '@/views/Events/AssignRoute.vue'
import eventServiceInstance from '@/api/event_service'
import moment from 'moment'
import EventInformationCard from '@/components/modules/events/EventInformationCard.vue'
import EventInformationDescriptionCard from '@/components/modules/events/EventInformationDescriptionCard.vue'
import EventCustomerInformation from '@/components/modules/events/EventCustomerInformation.vue'
import _ from 'lodash'
import { reactive } from 'vue'

export default {
  components: {
    AssignRoute,
    EventInformationCard,
    EventInformationDescriptionCard,
    EventCustomerInformation,
  },
  props: {
    eventRequestHandler: {
      type: Vue,
      required: false,
    },
  },
  data() {
    return {
      requestStatus: ['accepted', 'rejected', 'queried', 'pending'],
      show: false,
      items: [
        {
          text: 'Upcoming Events',
          href: '#',
        },
        {
          text: 'Event Information',
          href: '#',
        },
      ],
      customerDetails: reactive({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
      }),
      event: '',
      anEvent: reactive({
        id: '',
        title: '',
        description: '',
        location: '',
        payment: '',
        returnTrip: false,
        tripDate: null,
        returnDate: null,
        attendeesEstimate: 0,
        type: '',
        banner: '',
        status: '',
        eventLink: null,
        routeAssigned: false,
        eventRouteLink: ''
      }),
      anAttendee: [],
      isLoading: true,
      error: null,
      eventRouteModal: false,
      acceptanceLoading: false,
      rejectionLoading: false,
      queryLoading: false,
      attendeesNotificationLoading: false,
      routeAssignmentLoader: false,
      routesLoading: false,
      isLoadingGetRoutes: false,
      isOrganizerNotificationLoading: false,
      allRoutes: [],
      eventRoutes: [],
      routeSearchParam: ''
    }
  },
  created() {
    this.getEvent()
    
    this.eventRequestHandler.$on('acceptEvent', () => {
      this.acceptEvent()
    })

    this.eventRequestHandler.$on('rejectEvent', (data) => {
      this.rejectEvent(data)
    })

    this.eventRequestHandler.$on('queryEvent', (data) => {
      this.queryEvent(data)
    })

    this.eventRequestHandler.$on('notifyAttendees', () => {
      this.notifyAttendees()
    })

    this.eventRequestHandler.$on('assignRoutes', (data) => {
      this.assignRoutes(data)
    })
    this.eventRequestHandler.$on('searchRoutes', (data) => {
      this.routeSearchParam = data
      this.allRoutes = []
      this.$emit('update:allRoutes', this.allRoutes)
      this.debouncedCallback()
    })

    this.debouncedCallback = _.debounce(() => {
      this.getAllRoutes()
    }, 1000)
  },
  emits: [
    'update:eventStatus',
    'update:acceptanceLoading',
    'update:rejectionLoading',
    'update:queryLoading',
    'update:attendeesNotificationLoading',
    'update:routeAssignmentLoader',
    'update:allRoutes',
    'update:isLoadingGetRoutes',
    'update:isOrganizerNotificationLoading',
    'update:eventRoutes',
    'update:routeAssigned'
  ],
  watch: {
    statusFilter() {
      this.getEvent()
    },
  },
  methods: {
    formatDate(d) {
      return moment(d).format('YYYY-MM-DD')
    },

    updateEventRoutes(data) {
      this.eventRoutes = [...data]
    },

    async getEvent() {
      this.loading = true

      try {
        const res = await eventServiceInstance.getSingleEvent(this.$route.params.eventId)

        if ([200, 201].includes(res.status)) {
          this.anEvent = res.data.data
          this.customerDetails = res.data.data
          this.$emit('update:eventStatus', this.anEvent.status)
          this.$emit('update:routeAssigned', this.anEvent.routeAssigned)
          this.getAllRoutes()
          this.getAssignedRoutes()
          this.isLoading = false
        }
      } catch(error) {
        const msg =
          error.response && error.response.data
            ? error.response.data.message
            : 'An error occured, please try again.'
        this.$swal().showValidationMessage(msg)
      }
    },

    async acceptEvent() {
      this.$emit('update:acceptanceLoading', true)

      try {
        const res = await eventServiceInstance.acceptEvent(this.anEvent.id)

        if ([200, 201].includes(res.status)) {
          this.getEvent()
          this.$emit('update:acceptanceLoading', false)
          this.$bvModal.hide('accept-event-modal')
          this.$bvToast.show('event-accepted-toast')
        }
      } catch (error) {
        const msg =
          error.response && error.response.data
            ? error.response.data.message
            : 'An error occured, please try again.'
        this.$swal().showValidationMessage(msg)
      }
    },

    async rejectEvent(data) {
      this.$emit('update:rejectionLoading', true)

      try {
        const payload = {
          eventId: this.anEvent.id,
          feedback: data.description,
        }
        const res = await eventServiceInstance.rejectEvent(payload)
        if (res.status === 200) {
          this.getEvent()
          this.$emit('update:rejectionLoading', false)
          this.$bvModal.hide('reject-event-modal')
          this.$bvToast.show('event-rejected-toast')
        }
      } catch (error) {
        const msg =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Failed to reject event'
        this.$swal().showValidationMessage(msg)
      }
    },

    async queryEvent(data) {
      this.$emit('update:queryLoading', true)

      try {
        const payload = {
          eventId: this.anEvent.id,
          feedback: data.description,
        }
        const res = await eventServiceInstance.queryRequest(payload)
        if (res.status === 200) {
          this.getEvent()
          this.$emit('update:queryLoading', false)
          this.$bvModal.hide('query-event-modal')
          this.$bvToast.show('event-queried-toast')
        }
      } catch (error) {
        const msg =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Failed to query event'
        this.$swal().showValidationMessage(msg)
      }
    },

    async notifyAttendees() {
      this.$emit('update:attendeesNotificationLoading', true)

      try {
        const res = await eventServiceInstance.notifyAttendees(this.anEvent.id)
        if ([200, 201].includes(res.status)) {
          this.$bvToast.show('attendee-route-notification')
          this.$bvModal.hide('notify-attendees-modal')
        }

      } catch (error) {
        const msg =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Failed to notify attendees'
        this.$swal().showValidationMessage(msg)
      } finally {
        this.$emit('update:attendeesNotificationLoading', false)
      }
    },

    async getAllRoutes () {
      this.$emit('update:isLoadingGetRoutes', true)
      
      try  {
        const res = await eventServiceInstance.getRoutes(10, this.routeSearchParam)
        if (res.status === 200) {
          this.allRoutes = res.data.data
          this.$emit('update:allRoutes', this.allRoutes)
        }
      } catch(error) {
        const msg =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Failed to query event'
        this.$swal().showValidationMessage(msg)
      } finally {
        this.$emit('update:isLoadingGetRoutes', false)
      }
    },

    async assignRoutes (data) {
      this.$emit('update:routeAssignmentLoader', true)

      try {
        const res = await eventServiceInstance.assignRoutes(this.anEvent.id, data)

        if ([200, 201].includes(res.status)) {
          this.$bvModal.hide('event-route-modal')
          this.$bvToast.show('event-route-toast')
          // this.getAssignedRoutes()
          this.getEvent()
        }
      } catch(error) {
        const msg =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Failed to query event'
        this.$swal().showValidationMessage(msg)
      } finally {
        this.$emit('update:routeAssignmentLoader', false)
      }
    },

    async getAssignedRoutes() {
      this.routesLoading = true
      try {
        const res = await eventServiceInstance.getEventRoutes(this.anEvent.id)
        if ([201, 200].includes(res.status)) {
          this.eventRoutes = res.data
          this.$emit('update:eventRoutes', this.eventRoutes)
          this.routesLoading = false
        }
      } catch (error) {
        const msg =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Failed to query event'
        this.$swal().showValidationMessage(msg)
      }
    },

    async notifyOrganizer() {
      this.isOrganizerNotificationLoading = true

      try {
        const res = await eventServiceInstance.notifyOrganizer(this.anEvent.id)

        if ([200, 201].includes(res.status)) {
          this.$bvToast.show('organizer-notification-success')
        }
      } catch (error) {
        this.$toastr.e('There is an error', 'Error')
      } finally {
        this.isOrganizerNotificationLoading = false
      }
    },
  },
  beforeDestroy() {
    this.eventRequestHandler.$off('acceptEvent', () => {
      this.acceptEvent()
    })

    this.eventRequestHandler.$off('rejectEvent', (data) => {
      this.rejectEvent(data)
    })

    this.eventRequestHandler.$off('queryEvent', (data) => {
      this.queryEvent(data)
    })

    this.eventRequestHandler.$off('notifyAttendees', () => {
      this.notifyAttendees()
    })

    this.eventRequestHandler.$off('assignRoutes', (data) => {
      this.assignRoutes(data)
    })
    this.eventRequestHandler.$off('searchRoutes', (data) => {
      this.routeSearchParam = data
      this.debouncedCallback()
    })
  }
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/b-table';
.-mt-4{
  margin-top: -1.5rem;
}
 
img {
  width: 100%;
  height: 150px;
  border-radius: 5px;
  object-fit: cover;
}

.col-xl-6{
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.inactive {
  color: #6e84a3;
}

.breadcrumb-item {
  & a {
    color: #6e84a3;

    &:hover {
      color: #09090f;
    }
  }

  &.active {
    font-weight: 500;
    color: #09090f;
  }
}
.content {
  width: 100%;
}
</style>
