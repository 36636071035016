var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card event-info"},[_vm._m(0),_c('div',{staticClass:"card-body"},[(_vm.loading)?_c('div',{staticClass:"d-flex justify-content-center align-items-center p-4"},[_c('div',{staticClass:"spinner-border",attrs:{"role":"status"}})]):_c('div',{staticClass:"list-group list-group-flush"},[_c('div',{staticClass:"list-group-item"},[_c('div',{staticClass:"row align-items-center"},[_vm._m(1),_c('div',{staticClass:"col-auto"},[_c('span',{},[_vm._v(_vm._s(_vm.title))])])])]),(_vm.location !== undefined)?_c('div',{staticClass:"list-group-item"},[_c('div',{staticClass:"row align-items-center"},[_vm._m(2),_c('div',{staticClass:"col-auto"},[_c('span',{},[_vm._v(_vm._s(_vm.location))])])])]):_vm._e(),(_vm.pickupLocation !== undefined && _vm.programDays)?_c('div',{staticClass:"list-group-item"},[_c('div',{staticClass:"row align-items-center"},[_vm._m(3),_c('div',{staticClass:"col-auto"},[_vm._v(" "+_vm._s(_vm.pickupLocation)+" ")])])]):_vm._e(),(_vm.returnLocation !== undefined && _vm.programDays)?_c('div',{staticClass:"list-group-item"},[_c('div',{staticClass:"row align-items-center"},[_vm._m(4),_c('div',{staticClass:"col-auto"},[_vm._v(" "+_vm._s(_vm.returnLocation ? _vm.returnLocation : 'N/A')+" ")])])]):_vm._e(),(_vm.programDays !== undefined && _vm.programDays)?_c('div',{staticClass:"list-group-item"},[_c('div',{staticClass:"row align-items-center"},[_vm._m(5),_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"d-flex flex-wrap",staticStyle:{"gap":"5px 0px"}},_vm._l((_vm.getDays(
										`${_vm.tripDate}`,
										`${_vm.returnDate}`
									)),function(day,index){return _c('p',{staticClass:"text my-0 mx-1 weekdays"},[_vm._v(" "+_vm._s(_vm.formatToWeekDay(day))+" ")])}),0)])])]):_vm._e(),(_vm.payment !== undefined && !_vm.programDays)?_c('div',{staticClass:"list-group-item"},[_c('div',{staticClass:"row align-items-center"},[_vm._m(6),_c('div',{staticClass:"col-auto"},[_c('span',[_vm._v(_vm._s(_vm.payment))])])])]):_vm._e(),(_vm.returnTrip !== undefined && !_vm.programDays)?_c('div',{staticClass:"list-group-item"},[_c('div',{staticClass:"row align-items-center"},[_vm._m(7),_c('div',{staticClass:"col-auto"},[_vm._v(" "+_vm._s(_vm.returnTrip ? 'Return' : 'One-way')+" ")])])]):_vm._e(),(_vm.tripDate !== undefined && !_vm.programDays)?_c('div',{staticClass:"list-group-item"},[_c('div',{staticClass:"row align-items-center"},[_vm._m(8),_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"d-flex flex-wrap",staticStyle:{"gap":"5px 0px"}},_vm._l((_vm.getDays(
										`${_vm.tripDate}`,
										`${_vm.returnDate}`
									)),function(day,index){return _c('p',{staticClass:"text my-0 mx-1 weekdays"},[_vm._v(" "+_vm._s(day)+" ")])}),0)])])]):_vm._e(),(_vm.attendeesEstimate !== undefined && !_vm.programDays)?_c('div',{staticClass:"list-group-item"},[_c('div',{staticClass:"row align-items-center"},[_vm._m(9),_c('div',{staticClass:"col-auto"},[_vm._v(" ~"+_vm._s(_vm.attendeesEstimate)+" ")])])]):_vm._e(),(_vm.type !== undefined)?_c('div',{staticClass:"list-group-item"},[_c('div',{staticClass:"row align-items-center"},[_vm._m(10),_c('div',{staticClass:"col-auto"},[_vm._v(" "+_vm._s(_vm.type)+" ")])])]):_vm._e(),(_vm.eventLink !== undefined && _vm.eventLink)?_c('div',{staticClass:"list-group-item"},[_c('div',{staticClass:"row align-items-center"},[_vm._m(11),_c('div',{staticClass:"col-auto event-link"},[_c('a',{staticClass:"url",attrs:{"href":_vm.eventLink,"target":"_blank"}},[_vm._v(_vm._s(_vm.eventLink))]),_c('i',{staticClass:"fe fe-link link",on:{"click":function($event){return _vm.copyToClipboard(_vm.eventLink)}}})])])]):_vm._e(),(_vm.routeAssigned !== undefined && _vm.routeAssigned)?_c('div',{staticClass:"list-group-item"},[_c('div',{staticClass:"row align-items-center"},[_vm._m(12),_c('div',{staticClass:"col-auto event-link"},[_c('a',{staticClass:"url",attrs:{"href":_vm.eventRouteLink,"target":"_blank"}},[_vm._v(_vm._s(_vm.eventRouteLink))]),_c('i',{staticClass:"fe fe-link link",on:{"click":function($event){return _vm.copyToClipboard(_vm.eventRouteLink)}}})])])]):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header justify-content-between"},[_c('h4',{staticClass:"card-header-title"},[_vm._v("Event Information")]),_c('div',{staticClass:"dropdown"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col"},[_c('span',{staticClass:"text-muted text-uppercase"},[_vm._v("Title")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col"},[_c('span',{staticClass:"text-muted text-uppercase"},[_vm._v("Location")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col"},[_c('span',{staticClass:"text-muted text-uppercase"},[_vm._v("Nearest pick up bus stop")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col"},[_c('span',{staticClass:"text-muted text-uppercase"},[_vm._v("Return drop off bus stop")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col"},[_c('span',{staticClass:"text-muted text-uppercase"},[_vm._v("Return Date")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col"},[_c('span',{staticClass:"text-muted text-uppercase"},[_vm._v("Who will be paying")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col"},[_c('span',{staticClass:"text-muted text-uppercase"},[_vm._v("Trip type")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col"},[_c('span',{staticClass:"text-muted text-uppercase"},[_vm._v("Event Date")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col"},[_c('span',{staticClass:"text-muted text-uppercase"},[_vm._v("Estimated no. of attendees")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col"},[_c('span',{staticClass:"text-muted text-uppercase"},[_vm._v("Type of Event")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col"},[_c('span',{staticClass:"text-muted text-uppercase"},[_vm._v("Onboarding Link")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col"},[_c('span',{staticClass:"text-muted text-uppercase"},[_vm._v("Event Route Link")])])
}]

export { render, staticRenderFns }