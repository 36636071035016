<template>
	<div class="card event-info">
		<div class="card-header justify-content-between">
			<h4 class="card-header-title">Event Information</h4>
			<div class="dropdown"></div>
		</div>
		<div class="card-body">
			<div
				v-if="loading"
				class="d-flex justify-content-center align-items-center p-4"
				>
				<div class="spinner-border" role="status"></div>
			</div>
			<div class="list-group list-group-flush" v-else>
				<div class="list-group-item">
					<div class="row align-items-center">
						<div class="col">
							<span class="text-muted text-uppercase">Title</span>
						</div>
						<div class="col-auto">
							<span class="">{{ title }}</span>
						</div>
					</div>
				</div>
				<!-- <div class="list-group-item">
          <div class="row align-items-center">
            <div class="col">
              <span class="text-muted text-uppercase">Description</span>
            </div>
            <div class="col-auto">
              <span class="" v-html="description"></span>
            </div>
          </div>
        </div> -->
				<div v-if="location !== undefined" class="list-group-item">
					<div class="row align-items-center">
						<div class="col">
							<span class="text-muted text-uppercase">Location</span>
						</div>
						<div class="col-auto">
							<span class="">{{ location }}</span>
						</div>
					</div>
				</div>
				<div v-if="pickupLocation !== undefined && programDays" class="list-group-item">
					<div class="row align-items-center">
						<div class="col">
							<span class="text-muted text-uppercase">Nearest pick up bus stop</span>
						</div>
						<div class="col-auto">
							{{ pickupLocation }}
						</div>
					</div>
				</div>
				<div v-if="returnLocation !== undefined && programDays" class="list-group-item">
					<div class="row align-items-center">
						<div class="col">
							<span class="text-muted text-uppercase">Return drop off bus stop</span>
						</div>
						<div class="col-auto">
							{{ returnLocation ? returnLocation : 'N/A' }}
						</div>
					</div>
				</div>
				<div v-if="programDays !== undefined && programDays" class="list-group-item">
					<div class="row align-items-center">
						<div class="col">
							<span class="text-muted text-uppercase">Return Date</span>
						</div>
						<div class="col-auto">
							<div class="d-flex flex-wrap" style="gap: 5px 0px;">
								<p
									v-for="(day, index) in getDays(
										`${tripDate}`,
										`${returnDate}`
									)"
									class="text my-0 mx-1 weekdays"
									>
									{{
										formatToWeekDay(day)
									}}
								</p>
							</div>
						</div>
					</div>
				</div>
				<div v-if="payment !== undefined && !programDays" class="list-group-item">
					<div class="row align-items-center">
						<div class="col">
							<span class="text-muted text-uppercase">Who will be paying</span>
						</div>
						<div class="col-auto">
							<span>{{ payment }}</span>
						</div>
					</div>
				</div>
				<div v-if="returnTrip !== undefined && !programDays" class="list-group-item">
					<div class="row align-items-center">
						<div class="col">
							<span class="text-muted text-uppercase">Trip type</span>
						</div>
						<div class="col-auto">
							{{ returnTrip ? 'Return' : 'One-way' }}
						</div>
					</div>
				</div>
				<div v-if="tripDate !== undefined && !programDays" class="list-group-item">
					<div class="row align-items-center">
						<div class="col">
							<span class="text-muted text-uppercase">Event Date</span>
						</div>
						<div class="col-auto">
							<div class="d-flex flex-wrap" style="gap: 5px 0px;">
								<p
									v-for="(day, index) in getDays(
										`${tripDate}`,
										`${returnDate}`
									)"
									class="text my-0 mx-1 weekdays"
									>
									{{
										day
									}}
								</p>
							</div>
						</div>
					</div>
				</div>
				<div v-if="attendeesEstimate !== undefined && !programDays" class="list-group-item">
					<div class="row align-items-center">
						<div class="col">
							<span class="text-muted text-uppercase"
								>Estimated no. of attendees</span
								>
						</div>
						<div class="col-auto">
							~{{ attendeesEstimate }}
						</div>
					</div>
				</div>
				<div v-if="type !== undefined" class="list-group-item">
					<div class="row align-items-center">
						<div class="col">
							<span class="text-muted text-uppercase">Type of Event</span>
						</div>
						<div class="col-auto">
							{{ type }}
						</div>
					</div>
				</div>
				<div v-if="eventLink !== undefined && eventLink" class="list-group-item">
					<div class="row align-items-center">
						<div class="col">
							<span class="text-muted text-uppercase">Onboarding Link</span>
						</div>
						<div class="col-auto event-link">
							<a class="url" :href="eventLink" target="_blank">{{
								eventLink
							}}</a>
							<i class="fe fe-link link" @click="copyToClipboard(eventLink)"></i>
						</div>
					</div>
				</div>
				<div v-if="routeAssigned !== undefined && routeAssigned" class="list-group-item">
					<div class="row align-items-center">
						<div class="col">
							<span class="text-muted text-uppercase">Event Route Link</span>
						</div>
						<div class="col-auto event-link">
							<a class="url" :href="eventRouteLink" target="_blank">{{
								eventRouteLink
							}}</a>
							<i class="fe fe-link link" @click="copyToClipboard(eventRouteLink)"></i>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getDatesInRange } from '@/utils/helpers'
import { format } from 'date-fns'

export default {
  name: 'EventInformationCard',
  props: {
    title: {
      type: String,
      default: ''
    },
    location: {
      type: String,
      default: ''
    },
    payment: {
      type: String,
      default: ''
    },
    returnTrip: {
      type: Boolean,
      default: false
    },
    tripDate: {
      type: String,
      default: ''
    },
    returnDate: {
      type: String,
      default: ''
    },
    attendeesEstimate: {
      type: Number,
      default: 0
    },
    type: {
      type: String,
      default: ''
    },
    eventLink: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: true
    },
	pickupLocation: {
		type: String,
		default: ''
	},
	returnLocation: {
		type: String,
		default: ''
	},
	programDays: {
		type: Boolean,
		default: false
	},
	routeAssigned: {
		type: Boolean,
		default: false
	},
	eventRouteLink: {
		type: String,
		default: ''
	}
  },
  methods: {
    copyToClipboard (link) {
      navigator.clipboard.writeText(link)
      this.$toastr.s('Link copied successfully.')
    },
	getDays(startDate, endDate) {
      return getDatesInRange(startDate, endDate)
    },
    formatToWeekDay(day) {
      return format(new Date(day), 'EEEE')
    },
  }
}
</script>

<style lang="scss" scoped>
.card-body {
  padding: 0px 20px;
}

.list-group-item {
  font-size: 14px;
  line-height: 21px;
  padding: 20px 0px;
  & .text-muted {
    font-weight: 500;
  }
}

.event-info {
  width: 100%;
}

.event-link {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 50%;
  .url {
    text-overflow: ellipsis;
    color: unset;
    overflow: hidden;
    white-space: nowrap;
  }
  & .link {
    display: block;
    color: #4848ed;
    font-size: 20px;
    cursor: pointer;
  }
}

.weekdays {
	padding: 8px;
	font-size: 12px;
	background-color: #E6FFF2;
	border-radius: 8px
}

@media (min-width: 1200px) {
  .event-info {
    max-width: 620px;
  }
}
</style>
