<template>
	<div class="card event-info-desc">
		<div
			class="img-logo"
			:class="{
				'no-image': ['null', 'undefined', null, undefined].includes(banner),
			}"
			:style="{
				backgroundImage: banner ? `url(${banner})` : '',
				backgroundColor: '#b9bcc8',
				backgroundSize: '100% 100%',
				backgroundRepeat: 'no-repeat',
				backgroundPosition: 'center',
			}"
			></div>
		<div
			v-if="loading"
			class="d-flex justify-content-center align-items-center p-4"
			>
			<div class="spinner-border" role="status"></div>
		</div>
		<div v-if="!loading">
			<div class="p-4 m-2">
				<h1>
					{{ title }}
				</h1>
				<div v-html="description"></div>
			</div>
			<div class="card-footer p-4">
				<div :class="`logo-${status}`">Request {{ status }}</div>
				<button 
					v-if="status === 'accepted' && !attendee" 
					class="mybtn btn btn-primary" 
					type="button" 
					:disabled="isOrganizerNotificationLoading"
					@click="notifyOrganizer">
					<i class="fe bell-icon"></i>
					<span>{{ !isOrganizerNotificationLoading ? 'Notify Organizer' : 'Processing...'}}</span>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  name: 'EventInformationDescriptionCard',
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    banner: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    attendee: {
      type: Boolean,
      default: false
    },
    isOrganizerNotificationLoading: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['onNotifyOrganizer'],
  methods: {
    notifyOrganizer() {
      this.$emit('onNotifyOrganizer')
    }
  }
}
</script>

<style lang="scss" scoped>
.img-logo {
  width: 100%;
  height: 180px;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & .mybtn {
    background-color: #4848ed;
    outline: none;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;

    & i {
      background-color: #ffffff;
      width: 24px;
      height: 24px;
    }
  }
}

.bell-icon {
  display: inline-block;
  -webkit-mask-image: url('../../../assets/img/icons/notification.svg');
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-image: url('../../../assets/img/icons/notification.svg');
  mask-repeat: no-repeat;
  mask-position: center;
}

.no-image {
  display: flex;
  justify-content: center;
  align-items: center;
}
.no-image::after {
  content: 'No Image';
  white-space: nowrap;
  color: white;
}

.event-info-desc {
  width: 100%;
}

.logo-pending {
  color: #e7c805;
}

.logo-accepted {
  color: #0dac5c;
}

.logo-queried {
  color: #4848ed;
}

.logo-rejected {
  color: #e13d45;
}

@media (min-width: 1200px) {
  .event-info-desc {
    max-width: 428px;
  }
}
</style>
