var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card event-info-desc"},[_c('div',{staticClass:"img-logo",class:{
			'no-image': ['null', 'undefined', null, undefined].includes(_vm.banner),
		},style:({
			backgroundImage: _vm.banner ? `url(${_vm.banner})` : '',
			backgroundColor: '#b9bcc8',
			backgroundSize: '100% 100%',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
		})}),(_vm.loading)?_c('div',{staticClass:"d-flex justify-content-center align-items-center p-4"},[_c('div',{staticClass:"spinner-border",attrs:{"role":"status"}})]):_vm._e(),(!_vm.loading)?_c('div',[_c('div',{staticClass:"p-4 m-2"},[_c('h1',[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.description)}})]),_c('div',{staticClass:"card-footer p-4"},[_c('div',{class:`logo-${_vm.status}`},[_vm._v("Request "+_vm._s(_vm.status))]),(_vm.status === 'accepted' && !_vm.attendee)?_c('button',{staticClass:"mybtn btn btn-primary",attrs:{"type":"button","disabled":_vm.isOrganizerNotificationLoading},on:{"click":_vm.notifyOrganizer}},[_c('i',{staticClass:"fe bell-icon"}),_c('span',[_vm._v(_vm._s(!_vm.isOrganizerNotificationLoading ? 'Notify Organizer' : 'Processing...'))])]):_vm._e()])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }