<template>
	<div class="card event-customer-info">
		<div class="card-header justify-content-between">
			<h4 class="card-header-title">Customer Information</h4>
		</div>
		<div class="card-body">
			<div
				v-if="loading"
				class="d-flex justify-content-center align-items-center p-4"
				>
				<div class="spinner-border" role="status"></div>
			</div>
			<div class="list-group list-group-flush" v-else>
				<div class="list-group-item">
					<div class="row align-items-center">
						<div class="col">
							<span class="text-muted text-uppercase">Full name</span>
						</div>
						<div class="col-auto">
							<span class="">{{ `${firstName} ${lastName}` }}</span>
						</div>
					</div>
				</div>
				<div class="list-group-item">
					<div class="row align-items-center">
						<div class="col">
							<span class="text-muted text-uppercase">Email Address</span>
						</div>
						<div class="col-auto">
							<span class="">{{ email }}</span>
						</div>
					</div>
				</div>
				<div class="list-group-item">
					<div class="row align-items-center">
						<div class="col">
							<span class="text-muted text-uppercase">Phone Number</span>
						</div>
						<div class="col-auto">
							<span class="">{{ phone }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  name: 'EventCustomerInforamtion',
  props: {
    firstName: {
      type: String,
      required: true
    },
    lastName: {
      type: String,
      required: true
    },
    email: {
      type: String,
      required: true
    },
    phone: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      required: true,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
.card-body {
  padding: 0px 20px;
}

.list-group-item {
  font-size: 14px;
  line-height: 21px;
  padding: 20px 0px;
  & .text-muted {
    font-weight: 500;
  }
}

.event-customer-info {
  width: 100%;
}

@media (min-width: 1200px) {
  .event-customer-info {
    max-width: 620px;
  }
}
</style>
